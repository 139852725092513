body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
